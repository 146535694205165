import {
  ArticleCard,
  CampaignPromotion,
  DividedList,
  Heading,
  Helmet,
  IconDiversity,
  IconFindingWork,
  IconSchooling,
  ImageAlignment,
  Interfaces,
  Layout,
  LinkButton,
  NavigationTiles,
  NewsFeaturedArticle,
  PreStyledComponents,
  ReadMoreLink,
  RelatedPagesContent,
  ServiceCards,
  ServiceCardsDefaults,
  SubNavigationBox,
  Theme,
  Variables,
  media
} from "@life-without-barriers/react-components"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Homepage, HomepageCareersPanel } from "../contentTypes"
import React, { useState } from "react"
import { Site, getGatsbyImage } from "@life-without-barriers/gatsby-common"

import { graphql } from "gatsby"
import { helpers } from "@life-without-barriers/utilities"
import styled from "styled-components"

const { OurAEIPIcon, DiversityAndInclusionIcon, EducationUnit } =
  RelatedPagesContent
const { Container, GridWrapper, BackgroundHex } = PreStyledComponents
const { disabilityTheme, corporateThemeFull, agedCareThemeFull } = Theme
const { youthPinkLight, corporateGreenLight } = Variables

const { truncate } = helpers

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    openGraphImage: IGatsbyImageData
    agedCareImage: IGatsbyImageData
    disabilityImage: IGatsbyImageData
    fosterCareImage: IGatsbyImageData
    otherServicesImage: IGatsbyImageData
    homepageQuery: {
      nodes: Homepage[]
    }
    careersPanelQuery: {
      nodes: HomepageCareersPanel[]
    }
  }
  theme: Interfaces.Theme
}

const assertSingleHomepage = (homepageQuery: { nodes: Homepage[] }) => {
  if (homepageQuery.nodes.length < 1) {
    throw new Error(
      "A single homepage should exist but none was found. Has it been created in Contentful?"
    )
  } else if (homepageQuery.nodes.length > 1) {
    throw new Error(
      "A single homepage should exist but multiple were found. If you have changed Contentful environments on the same branch, you may need to clear the runner cache."
    )
  } else {
    return homepageQuery.nodes[0]
  }
}

interface BackgroundBannerProps {
  children: React.ReactChild
  agedCareImage: IGatsbyImageData
  disabilityImage: IGatsbyImageData
  fosterCareImage: IGatsbyImageData
  otherServicesImage: IGatsbyImageData
  serviceImage: IGatsbyImageData
  serviceColor: string
  selectedService: string
}

const BackgroundBanner = ({
  children,
  agedCareImage,
  disabilityImage,
  fosterCareImage,
  otherServicesImage,
  serviceColor,
  selectedService
}: BackgroundBannerProps) => (
  <BannerWrapper color={serviceColor} className="relative z-0">
    <div className="z-2 relative">{children}</div>
    <div>
      <StyledImageWrapper
        className={`${
          selectedService === "agedcare" ? "fadeIn" : ""
        } absolute absolute--fill z-1 overflow-hidden`}
      >
        <BannerImage
          loading="eager"
          title={`Banner Image`}
          image={getGatsbyImage(agedCareImage)}
          alt=""
        />
      </StyledImageWrapper>
      <StyledImageWrapper
        className={`${
          selectedService === "disability" ? "fadeIn" : ""
        } absolute absolute--fill z-1 overflow-hidden`}
      >
        <BannerImage
          loading="eager"
          title={`Banner Image`}
          image={getGatsbyImage(disabilityImage)}
          alt=""
        />
      </StyledImageWrapper>
      <StyledImageWrapper
        className={`${
          selectedService === "fostercare" ? "fadeIn" : ""
        }  absolute absolute--fill z-1 overflow-hidden`}
      >
        <BannerImage
          loading="eager"
          title={`Banner Image`}
          image={getGatsbyImage(fosterCareImage)}
          alt=""
        />
      </StyledImageWrapper>
      <StyledImageWrapper
        className={`${
          selectedService === "otherservices" ? "fadeIn" : ""
        } absolute absolute--fill z-1 overflow-hidden`}
      >
        <BannerImage
          loading="eager"
          title={`Banner Image`}
          image={getGatsbyImage(otherServicesImage)}
          alt=""
          className="home-hero-image-right"
        />
      </StyledImageWrapper>
      <div className={`z-0 absolute absolute--fill overflow-hidden`}>
        <BannerImage
          loading="eager"
          title={`Banner Image`}
          image={getGatsbyImage(otherServicesImage)}
          alt=""
          className="home-hero-image-right"
        />
      </div>
    </div>
  </BannerWrapper>
)

interface ColorProps {
  color: string
}

const ServicesContainerWrapper = styled(Container)`
  @media screen and (max-width: 1280px) and (min-width: 960px) {
    padding-bottom: 4rem;
  }
`

const BannerWrapper = styled.div`
  background-color: ${(props: ColorProps) => props.color};

  @media screen and (min-width: 80rem) {
    background-color: rgb(102, 102, 102);

    :after {
      background: rgba(10, 26, 25, 0.6);
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
`

const StyledImageWrapper = styled.div`
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.fadeIn {
    transition: opacity 0.3s ease-in-out;
    opacity: 5;
  }
`

const BannerImage = styled(GatsbyImage)`
  bottom: 0;
  height: 170px;
  margin: auto;
  max-width: 2000px;

  :after {
    background: rgba(0, 0, 0, 0.6);
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  ${media.medium`
    height: 270px;
  `}

  @media screen and (min-width: 60rem) and (max-width: 80rem) {
    height: 300px;
  }

  @media screen and (min-width: 80rem) {
    height: 100%;

    :before {
      left: 0px;
      background: linear-gradient(to right, rgb(102, 102, 102), transparent);
    }

    :after {
      right: 0px;
      background: linear-gradient(to left, rgb(102, 102, 102), transparent);
    }

    :before,
    :after {
      bottom: 0px;
      content: "";
      position: absolute;
      top: 0px;
      width: 400px;
      z-index: 1;
    }
  }

  img {
    max-width: none !important;
  }
`

const StyledHeroBannerHeading = styled.h1`
  font-size: 39px;
  line-height: 1.2;
  padding-bottom: 3rem;

  ${media.notSmall`
    line-height: 82px;
  `}

  ${media.medium`
    font-size: 57px;
    padding-bottom: 6rem;
  `}

  @media screen and (max-width: 960px) and (min-width: 760px) {
    font-size: 71px;
    padding-bottom: 6rem;
    margin: auto;
    width: 80%;
  }

  ${media.large`
    font-size: 66px;
    padding-bottom: 6rem;
    padding-top: 3rem;
    margin: auto;
    max-width: 600px;
  `}

  @media screen and (min-width: 1280px) {
    padding-bottom: 0rem;
    padding-top: 5rem;
  }
`

const StyledHeroBannerSubHeading = styled.p`
  font-size: 19px;
  line-height: 1.5;
  padding-bottom: 2rem;

  @media screen and (min-width: 1280px) {
    padding-bottom: 4rem;
  }

  ${media.large`
    font-size: 24px;
  `}
`

const assertSingleHomepageCareersPanel = (careersPanelQuery: {
  nodes: HomepageCareersPanel[]
}) => {
  if (careersPanelQuery.nodes.length < 1) {
    throw new Error(
      "A single homepage careers panel should exist but none was found. Has it been created in Contentful?"
    )
  } else if (careersPanelQuery.nodes.length > 1) {
    throw new Error(
      "A single homepage should exist but multiple were found. If you have changed Contentful environments on the same branch, you may need to clear the runner cache."
    )
  } else {
    return careersPanelQuery.nodes[0]
  }
}

const iconSelector = (
  type: string,
  color: string,
  height?: string,
  width?: string
): JSX.Element | undefined => {
  if (type === "findingwork") {
    return <IconFindingWork color={color} height={height} width={width} />
  }
  if (type === "diversity") {
    return <IconDiversity color={color} height={height} width={width} />
  }
  if (type === "schooling") {
    return <IconSchooling color={color} height={height} width={width} />
  }
  return undefined
}

const IndexPage = ({
  location,
  data: {
    site: { siteMetadata },
    agedCareImage,
    disabilityImage,
    fosterCareImage,
    otherServicesImage,
    openGraphImage,
    homepageQuery,
    careersPanelQuery
  }
}: Props) => {
  const [serviceImage, setServiceImage] = useState(otherServicesImage)
  const [serviceColor, setServiceColor] = useState(corporateGreenLight)
  const [selectedService, setSelectedService] = useState("corporate")

  const {
    agedCareDefaults,
    disabilityDefaults,
    fosterCareDefaults,
    otherServicesDefaults
  } = ServiceCardsDefaults

  const disability = {
    ...disabilityDefaults,
    onSelectService: (service: string) => {
      setSelectedService(service)
      setServiceImage(serviceImage)
      setServiceColor(disabilityTheme.light)
    }
  }

  const agedCare = {
    ...agedCareDefaults,
    onSelectService: (service: string) => {
      setSelectedService(service)
      setServiceImage(agedCareImage)
      setServiceColor(agedCareThemeFull.light)
    }
  }

  const fosterCare = {
    ...fosterCareDefaults,
    onSelectService: (service: string) => {
      setSelectedService(service)
      setServiceImage(fosterCareImage)
      setServiceColor(youthPinkLight)
    }
  }

  const otherServices = {
    ...otherServicesDefaults,
    onSelectService: (service: string) => {
      setSelectedService(service)
      setServiceImage(otherServicesImage)
      setServiceColor(corporateGreenLight)
    }
  }
  const homepage = assertSingleHomepage(homepageQuery)
  const { campaign1, campaign2, featuredNews } = homepage
  const careersPanel = assertSingleHomepageCareersPanel(careersPanelQuery)

  return (
    <div>
      <Helmet
        title={`Life Without Barriers | ${siteMetadata.title}`}
        description="Foster Care, Aged Care, Disability and Mental Health services across Australia."
        image={openGraphImage}
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        includeGoogleSchema
      />
      <Layout theme={corporateThemeFull}>
        <BackgroundBanner
          agedCareImage={agedCareImage}
          disabilityImage={disabilityImage}
          fosterCareImage={fosterCareImage}
          otherServicesImage={otherServicesImage}
          selectedService={selectedService}
          serviceImage={serviceImage}
          serviceColor={serviceColor}
        >
          <ServicesContainerWrapper className="ph3 ph4-ns pv4 pv5-m pb6-l">
            <div className="color-lwb-white tc">
              <StyledHeroBannerHeading className="fw8 vera-mono">
                LIFE WITHOUT BARRIERS
              </StyledHeroBannerHeading>
              <StyledHeroBannerSubHeading className="fw8 pb4 pb5-l">
                Our purpose is to partner with people and change lives for the
                better
              </StyledHeroBannerSubHeading>
            </div>
            <div className="flex justify-center">
              <ServiceCards
                services={[fosterCare, disability, agedCare, otherServices]}
              />
            </div>
          </ServicesContainerWrapper>
        </BackgroundBanner>
        {campaign1 && (
          <CampaignPromotion
            heading={campaign1.heading}
            image={campaign1.image}
            description={campaign1.description.childMarkdownRemark.html}
            bannerAlignment={ImageAlignment.Left}
            cta={{
              title: "",
              label: campaign1.buttonLabel,
              url: campaign1.url
            }}
            index={1}
            theme={Theme.getThemeFromCategory(
              helpers.dasherize(campaign1.category).toLowerCase()
            )}
          />
        )}
        <div className="ph3 ph4-ns pv4 pv5-l tc">
          <Container>
            <div className="w-100 w-50-l center">
              <h2 className="ts-display-3 fw8 mb2 mb0-l">
                About Life Without Barriers
              </h2>
              <p>
                We believe what unites us are the rights we have and should all
                enjoy as human beings. Yet the opportunity to live freely,
                without significant barriers, is not afforded to all
                Australians. This drives us, a collective of diverse and
                passionate people to partner through relationships and change
                lives for the better.
              </p>
              <LinkButton className="mt4" to="/about-us" rooted>
                Learn more
              </LinkButton>
            </div>
          </Container>
        </div>
        <CampaignPromotion
          heading={campaign2.heading}
          image={campaign2.image}
          description={campaign2.description.childMarkdownRemark.html}
          bannerAlignment={
            campaign1 ? ImageAlignment.Right : ImageAlignment.Left
          }
          cta={{
            title: "",
            label: campaign2.buttonLabel,
            url: campaign2.url
          }}
          index={1}
          theme={Theme.getThemeFromCategory(
            helpers.dasherize(campaign2.category).toLowerCase()
          )}
        />
        <div className="ph3 ph4-ns pv4 pv5-l">
          <Container>
            <Heading size={2} sectionHeading className="mb2 mb0-l">
              News
            </Heading>
            <div className="db dn-l">
              <DividedList>
                <>
                  {featuredNews.map((article, index) => (
                    <li className="pv3" key={index}>
                      <NewsFeaturedArticle
                        href={`/news/${article.slug}`}
                        category={article.category}
                        title={truncate(article.title, 65)}
                        copy={truncate(article.introductionText.text, 110)}
                        rooted
                      />
                    </li>
                  ))}
                </>
              </DividedList>
            </div>
            <GridWrapper className="dn mt2 flex-l flex-wrap-ns">
              {featuredNews.map((article, index) => (
                <ArticleCard
                  key={index}
                  href={`/news/${article.slug}`}
                  category={article.category}
                  title={truncate(article.title, 65)}
                  copy={truncate(article.introductionText.text, 110)}
                  image={article.featureImage.gatsbyImageData}
                  rooted
                />
              ))}
            </GridWrapper>

            <div className="w100 tc">
              <ReadMoreLink
                className="mt3 mt4-ns"
                href="/news/"
                title="See all news stories"
                text="See all news stories"
              />
            </div>
          </Container>
        </div>
        <div className="ph3 ph4-ns pv4 pv5-ns bg-lwb-theme-xxx-light">
          <Container className="bg-lwb-theme-xxx-light flex flex-column flex-row-l justify-between">
            <div className="w-50-l mr5">
              <h2 className="ts-display-3 fw8">Want to join our team?</h2>
              <div className="dn db-l">
                <p className="copy fw6">
                  The Life Without Barriers community includes over 8,000 staff
                  working with thousands of people across Australia. We are
                  always on the lookout for passionate, values driven people to
                  join our team.
                </p>
                <div className="dib">
                  <LinkButton className="mt5" to="/careers" rooted>
                    Visit careers page
                  </LinkButton>
                </div>
              </div>
            </div>
            <div className="w-50-l mt4 mt0-l">
              <SubNavigationBox
                links={[
                  careersPanel.openPosition1,
                  careersPanel.openPosition2,
                  careersPanel.openPosition3
                ].map(({ title, url }) => ({
                  label: title,
                  to: url
                }))}
              />
              <ReadMoreLink
                className="mt4"
                text="See all vacancies"
                href="https://careers.lwb.org.au/en/listing/"
                styles={{
                  color: corporateThemeFull.dark,
                  iconColor: corporateThemeFull.dark,
                  hoverColor: corporateThemeFull.xdark
                }}
              />
            </div>
          </Container>
        </div>
        <BackgroundHex className="ph3 ph4-ns pv4 pv5-ns">
          <Container>
            <NavigationTiles
              heading={
                <h2 className="color-lwb-theme-medium ts-display-3 tc fw8">
                  How we influence social policy
                </h2>
              }
              currentPathname={location.pathname}
              selectIconFn={iconSelector}
              pages={[OurAEIPIcon, DiversityAndInclusionIcon, EducationUnit]}
            />
          </Container>
        </BackgroundHex>
      </Layout>
    </div>
  )
}

export const featuredImageFragment = graphql`
  fragment FeaturedImageFragment on ContentfulAsset {
    file {
      url
      fileName
      contentType
      details {
        size
        image {
          width
          height
        }
      }
    }
    title
    description
    gatsbyImageData(layout: CONSTRAINED)
  }
`

export const campaignFragment = graphql`
  fragment HomepageCampaign on ContentfulHomepageCampaign {
    heading
    description {
      text: description
      childMarkdownRemark {
        html
      }
    }
    buttonLabel
    url
    category
    image {
      gatsbyImageData(layout: CONSTRAINED)
    }
  }
`

export const openPositionFragment = graphql`
  fragment HomepageOpenPosition on ContentfulHomepageOpenPosition {
    title
    url
  }
`

export const query = graphql`
  {
    openGraphImage: file(relativePath: { regex: "/lwb-disability.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90, layout: FIXED)
      }
    }
    agedCareImage: file(
      relativePath: { regex: "/hero-banner-aged-care.png/" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 90, layout: FULL_WIDTH)
      }
    }
    disabilityImage: file(
      relativePath: { regex: "/hero-banner-disability.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 90, layout: FULL_WIDTH)
      }
    }
    fosterCareImage: file(
      relativePath: { regex: "/hero-banner-foster-care.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 90, layout: FULL_WIDTH)
      }
    }
    otherServicesImage: file(
      relativePath: { regex: "/hero-banner-other-services.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 90, layout: FULL_WIDTH)
      }
    }

    homepageQuery: allContentfulHomepage(filter: { name: { eq: "Homepage" } }) {
      nodes {
        campaign1 {
          ...HomepageCampaign
        }
        campaign2 {
          ...HomepageCampaign
        }
        featuredNews {
          slug
          title
          category
          introductionText {
            text: introductionText
          }
          date: date(formatString: "D MMM YYYY")
          featureImage {
            ...FeaturedImageFragment
          }
        }
      }
    }
    careersPanelQuery: allContentfulHomepageCareersPanel {
      nodes {
        openPosition1 {
          ...HomepageOpenPosition
        }
        openPosition2 {
          ...HomepageOpenPosition
        }
        openPosition3 {
          ...HomepageOpenPosition
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

export default IndexPage
